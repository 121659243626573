<template>
  <footer class="bg-black mt-auto">
    <div class="mx-5 xl:mx-auto grid grid-cols-2 max-w-7xl py-12">
      <div class="flex gap-5 col-span-2 sm:col-span-1 items-center justify-center sm:justify-normal">
        <div class="flex items-center">
          <img src="~/assets/h-dark-logo.svg" alt="Logo">
        </div>
        <a href="https://www.instagram.com/qummy_group/" target="_blank" class="ml-12">
          <img src="~/assets/instagram.svg" alt="Instagram">
        </a>
        <a href="https://www.facebook.com/qummy.home/" target="_blank">
          <img src="~/assets/facebook.svg" alt="Facebook">
        </a>
        <a href="https://www.youtube.com/@qummygroup5866" target="_blank">
          <img src="~/assets/youtube.svg" alt="Youtube">
        </a>
      </div>
      <div class="flex flex-col col-span-2 sm:col-span-1 text-white text-right mt-2 text-xs md:text-base">
        <a href="tel:88002509501">
          <span class="block">8 (800) 250-95-01</span>
        </a>
        <span class="block">г. Краснодар, ул. Красных Партизан, д. 367</span>
        <span class="block">г. Москва, Научный проезд 17</span>
      </div>
    </div>
  </footer>
</template>
